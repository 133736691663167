import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "get-packet"
    }}>{`Get Packet`}</h1>
    <p>{`Returns a Packet.`}</p>
    <p><strong parentName="p">{`GET`}</strong>{` `}<inlineCode parentName="p">{`/packets/get`}</inlineCode></p>
    <hr></hr>
    <h4 {...{
      "id": "parameter-specifications"
    }}>{`Parameter Specifications`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Key`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account's API Key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Packet Id of the Packet to retrieve.`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Example Response`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Result": "1",
  "Error_Messages": {},
  "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c",
  "Data": {
    "Packet_Id": "4447d2c1-6d74-43f9-b7e1-5204df047b43",
    "Packet_Active": 0,
    "Packet_Sequential_Signing": 1,
    "Packet_Expiration_Date": "2021-10-06",
    "Packet_Created_Callback_URL": "https://website.com/callback/packet_created",
    "Packet_Action_Callback_URL": "https://website.com/callback/packet_action",
    "Packet_Completed_Callback_URL": "https://website.com/callback/packet_completed",
    "Documents": {
      {
        "Packet_Document_Id": "3d71b8c9-4d63-436a-934a-81ee27d0157e",
        "Completed_Document_Link": "https://spf-api.com/api/v2/documents/download/3d71b8c9-4d63-436a-934a-81ee27d0157e",
        "Google_Drive_File_Id": "1ezbiCl3PFB08AlRCS-DcBzIboKn393Jw1VJWeeZjNQQ",
        "Document_Name": "My Legal Document",
        "Email_Doc_When_Complete": 1,
        "Document_Fields": {},
        "My_Custom_Fields": {}
    }
    "Persons": {
      {
        "Packet_Person_Id": "9ad1294c-79ee-4ee0-8ba0-56346bae7cb2",
        "Packet_Person_View_URL": "https://esign.run/9ad1294c",
        "First_Name": "John",
        "Middle_Name": "",
        "Last_Name": "Doe",
        "Email": "john.doe@website.com",
        "Phone": "5124009186",
        "Deactivate_Prior_Packets": 0,
        "Is_Receiver": 0,
        "Is_Reader": 0,
        "Is_Signer": 1,
        "Signer_Num": 1,
        "Send_Request_To_Sign_Msg": 0,
        "Read_Only_After_Completion": 0,
        "Simple_Sign": 1,
        "Email_Completed_Docs": 1,
        "Signer_Fields": {},
        "Signer_Custom_Consents": {},
        "My_Custom_Fields": {}
      }
    }
    "Completed_UTC_DateTime": "2021-10-04 13:10:21",
    "Created_By_User_Id": "626c6ff7-9f87-492b-8f5b-9155e24e781a",
    "Created_UTC_DateTime": "2021-10-03 15:12:42"
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      